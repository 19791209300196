export const initTiers = user => {
  // CHECKS IF USER HAS MADE PAYMENT FOR SPECIFIC TIER
  let tiers: Array<String | null> = []
  if (user?.payment_1 == 'confirmed') {
    tiers.push('Tier 1')
  }
  if (user?.payment_2 == 'confirmed') {
    tiers.push('Tier 2')
  }
  if (user?.payment_3 == 'confirmed') {
    tiers.push('Tier 3')
  }
  if (user?.payment_4 == 'confirmed') {
    tiers.push('Tier 4')
  }
  return tiers
}
