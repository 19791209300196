import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FormSteps from './form-steps'
import FormStepsRenewal from './form-steps-renewal'
import { Card, Label, Alert, Close } from 'theme-ui'
import { navigate } from 'gatsby'

// detailed breakdown of form logic can be found here: https://medium.com/@phebianchukwurah/multistep-form-with-react-hooks-e57be1e31995
const RegisterUser = ({ user }) => {
  let [step, setStep] = useState(0)
  let [submissionTier, setSubmissionTier] = useState('tier-4')
  let steps = FormSteps(step, setStep, submissionTier, setSubmissionTier)
  let renewalSteps = FormStepsRenewal(
    step,
    setStep,
    submissionTier,
    setSubmissionTier
  )

  const isRenewal = (): Boolean => {
    let searchTerm = 'renewal'
    if (submissionTier.includes(searchTerm)) {
      console.log('this')
      return true
    } else {
      return false
    }
  }

  async function handleSkipLogbook() {
    // TIER-1 CANDIDATES DO NOT NEED TO SUBMIT LOGBOOK
    if (submissionTier === 'tier-1' && step == 3) {
      setStep(step + 1)
    }
  }

  useEffect(() => {
    handleSkipLogbook()
  }, [step])

  return (
    <>
      {isRenewal() ? (
        <FormHead>
          <h2>{renewalSteps[`${step}`].title}</h2>
          <Card variant='paper' sx={{ maxWidth: 500 }}>
            <Label color='#537BE5'>
              Step {step + 1}/{renewalSteps.length}
            </Label>
            <div>{renewalSteps[`${step}`].content}</div>
          </Card>
        </FormHead>
      ) : (
        <FormHead>
          <h2>{steps[`${step}`].title}</h2>
          <Card variant='paper' sx={{ maxWidth: 500 }}>
            <Label color='#537BE5'>
              Step {step + 1}/{steps.length}
            </Label>
            <div>{steps[`${step}`].content}</div>
          </Card>
        </FormHead>
      )}
    </>
  )
}

export default RegisterUser

const FormHead = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 4em;
  h2 {
    text-align: center;
  }
`
