import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Button, Spinner, Flex } from 'theme-ui'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Completed = () => {
  return (
    <>
      <p> Your files have been received. </p>
      <p> Kindly wait while we review your application documents.</p>
      <p>
        {' '}
        For further enquiries, reach out to us <Link to='/contact'>here</Link>
      </p>
    </>
  )
}

Completed.propTypes = {
  user: PropTypes.object
}

export default Completed
