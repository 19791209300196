import React from 'react'
import FileDetails from './file-details'
import FileDetailsSingle from './file-details-single'
import Completed from './completed'
import SelectAccreditation from './select-accreditation'
import Payment from '../Payment'

const FormSteps = (step, setStep, submissionTier, setSubmissionTier) => [
  {
    title: 'Select Submission Tier',
    content: (
      <SelectAccreditation
        setSubmissionTier={setSubmissionTier}
        setStep={setStep}
        step={step}
        inputType={'payment'}
        helpText={'Which Tier are you submitting files for ? '}
      />
    )
  },
  {
    title: 'Verify',
    content: (
      <FileDetailsSingle
        setStep={setStep}
        step={step}
        inputType={'passport_front'}
        helpText={'Please upload the front of your passport or NRIC'}
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Verify',
    content: (
      <FileDetailsSingle
        setStep={setStep}
        step={step}
        inputType={'passport_back'}
        helpText={'Please upload the back of your passport or NRIC'}
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Upload Logbook Details',
    content: (
      <FileDetailsSingle
        setStep={setStep}
        step={step}
        inputType={'logbook'}
        helpText={'Download logbook template'}
        link={'application-documents-for-download'}
        submissionTier={submissionTier}
      />
    ),
    url: <h2>Hello World</h2>
  },
  {
    title: 'Upload Course Certificates',
    content: (
      <FileDetails
        setStep={setStep}
        step={step}
        inputType={'certs'}
        helpText={
          'Any academic or extra-curricular certifications are acceptable. We accept multiple files.'
        }
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Upload Supporting Documents',
    content: (
      <FileDetails
        setStep={setStep}
        step={step}
        inputType={'bim'}
        helpText={
          'Samples of previous BIM works (BIM Execution plan, Native BIM files) are acceptable. We accept multiple files.'
        }
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Upload CV',
    content: (
      <FileDetailsSingle
        setStep={setStep}
        step={step}
        inputType={'cv'}
        helpText={'Your personal CV or Resume'}
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Upload Passport Photo',
    content: (
      <FileDetailsSingle
        setStep={setStep}
        step={step}
        inputType={'photo'}
        helpText={'Insert your most recent passport-sized photo'}
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Payment',
    content: <Payment tier={submissionTier} step={step} setStep={setStep} />
  },
  {
    title: 'Success!',
    content: <Completed />
  }
]
export default FormSteps
