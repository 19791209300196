import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const Forum = () => {
  return (
    <>
      <Seo title='Forum' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header='Discussion Forum' subheader='Ask us Anything!' />
          <Divider />
          <Disqus />
        </Main>
        <Sidebar>
          <Divider />
        </Sidebar>
      </Stack>
    </>
  )
}

export default Forum
