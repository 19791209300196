/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkout = /* GraphQL */ `
  query Checkout($args: StripeInput) {
    checkout(args: $args)
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      nationality
      passStatus
      workExperience
      personalPhone
      personalEmail
      company
      companyEmail
      supervisorName
      supervisorEmail
      supervisorPhone
      approvalStatus
      certificationNumber
      certificationType
      certificationDate
      updatedAt
      nric
      currentDesignation
      numberBim
      supervisorDesignation
      paymentStatus
      payment_1
      payment_2
      payment_3
      payment_4
      createdAt
      __typename
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        nationality
        passStatus
        workExperience
        personalPhone
        personalEmail
        company
        companyEmail
        supervisorName
        supervisorEmail
        supervisorPhone
        approvalStatus
        certificationNumber
        certificationType
        certificationDate
        updatedAt
        nric
        currentDesignation
        numberBim
        supervisorDesignation
        paymentStatus
        payment_1
        payment_2
        payment_3
        payment_4
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
