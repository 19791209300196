import React, { useEffect, useReducer, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import styled from 'styled-components'
import { Card, Label, Box, Input, Button, Select, Message } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import data from '../../data.json'
import { checkout, getUser } from '@elegantstack/amplify/graphql/queries'
import { initTiers } from '../../hooks/initTiers'

interface FormValueInput {
  nationality: String
  accreditation: String
}

const Payment = ({ user, tier, step, setStep }) => {
  const [cognitoUser, setCognitoUser] = useState('')
  const [paidTiers, setPaidTiers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { register, handleSubmit, watch, getValues } = useForm<FormValueInput>()
  let { sub, email } = cognitoUser

  function getPrice(nationality, accreditation) {
    let obj = data.filter(item => item.name === accreditation)[0]
    if (nationality === 'Local') return obj.unit_amount_local

    return obj.unit_amount_foreigner
  }

  function handleBack() {
    if (step === 0) {
      navigate('/app/profile')
      return
    }

    setStep(step - 1)
  }

  async function handleStripeCheckout() {
    let token
    let stripeResponse
    let { nationality, accreditation } = getValues()
    let price = getPrice(nationality, accreditation)
    let url = window.location.origin + '/app/profile'

    try {
      const response = await API.graphql({
        query: checkout,
        variables: {
          args: {
            user_id: sub,
            tier: accreditation,
            unit_amount: price,
            quantity: 1,
            email: email,
            url: url
          }
        }
      })
      stripeResponse = response.data.checkout
      console.log(stripeResponse)
    } catch (err) {
      console.log('stripe checkout failed', err)
    } finally {
      window.location.replace(stripeResponse)
    }
  }

  async function getCognitoUser() {
    try {
      let data = await Auth.currentUserInfo()
      setCognitoUser(data.attributes)
    } catch (err) {
      console.log('failed to  retrieve user details from Auth', cognitoUser)
    }
  }

  async function initialisePaidTiers() {
    // CHECKS IF USER HAS MADE PAYMENT FOR SPECIFIC TIER
    let tiers = initTiers(user)
    setPaidTiers(tiers)
  }

  useEffect(() => {
    getCognitoUser()
  }, [])

  useEffect(() => {
    initialisePaidTiers()
  }, [])

  return (
    <Card variant='paper' sx={{ minWidth: 400, padding: 0 }}>
      <form onSubmit={handleSubmit(handleStripeCheckout)}>
        <Box>
          <Label>Nationality</Label>
          <Select defaultValue={'Foreigner'} name='nationality' ref={register}>
            <option>Local</option>
            <option>Foreigner</option>
          </Select>
        </Box>

        <Box mt={2} mb={3}>
          <Label>Accreditation</Label>
          <Select defaultValue={tier} name='accreditation' ref={register}>
            {data
              ? data.map(option => {
                  if (paidTiers.includes(option.name)) {
                    return (
                      <option value={option.name} disabled>
                        {option.label} (Paid)
                      </option>
                    )
                  }
                  return <option value={option.name}>{option.label}</option>
                })
              : null}
          </Select>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Button variant='primary' type='submit'>
            Make Payment
          </Button>
          <Button onClick={() => handleBack()} variant='back' type='reset'>
            Back
          </Button>
        </Box>
      </form>
    </Card>
  )
}

export default Payment
