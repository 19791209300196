import React from 'react'
import FileDetails from './file-details'
import FileDetailsSingle from './file-details-single'
import Completed from './completed'
import SelectAccreditation from './select-accreditation'
import Payment from '../Payment'

const FormStepsRenewal = (step, setStep, submissionTier, setSubmissionTier) => [
  {
    title: 'Select Submission Tier',
    content: (
      <SelectAccreditation
        setSubmissionTier={setSubmissionTier}
        setStep={setStep}
        step={step}
        inputType={'payment'}
        helpText={'Which Tier are you submitting files for ? '}
      />
    )
  },
  {
    title: 'Declaration of Continual Professional Development',
    content: (
      <FileDetails
        setStep={setStep}
        step={step}
        inputType={'cpd'}
        helpText={
          'Demonstrate at least 20 hours of Continual Professional Development with certificates of courses or events attended.'
        }
        submissionTier={submissionTier}
      />
    )
  },
  {
    title: 'Payment',
    content: <Payment tier={submissionTier} step={step} setStep={setStep} />
  },
  {
    title: 'Success!',
    content: <Completed />
  }
]
export default FormStepsRenewal
