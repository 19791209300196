import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Card, Label, Box, Select, Button, Slider } from 'theme-ui'
import { AssessmentInterface } from '../../interfaces/Form'

// detailed breakdown of form logic can be found here: https://medium.com/@phebianchukwurah/multistep-form-with-react-hooks-e57be1e31995
const Asseessment = () => {
  const optionsA = ['Degree', 'Diploma/Nitec', 'No Relevant Qualifications']

  const [userValues, setUserValues] = useState<AssessmentInterface | undefined>(
    {
      education: 'Degree',
      workExperience: null,
      yearsLocal: null,
      numberProj: null,
      isSubmit: false
    }
  )

  const handleInputChange = event => {
    setUserValues({ ...userValues, [event.target.name]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setUserValues({
      ...userValues,
      isSubmit: true
    })
  }

  const renderResult = () => {
    switch (true) {
      case userValues.education == 'Degree' &&
        userValues.workExperience >= 9 &&
        userValues.numberProj >= 5 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Chief Digital Officer (Tier 1)
            Accreditation
          </div>
        )
      case userValues.education == 'Degree' &&
        userValues.workExperience >= 4 &&
        userValues.numberProj >= 5 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Digital Delivery Lead (Tier 2)
            Accreditation
          </div>
        )
      case userValues.education == 'Diploma/Nitec' &&
        userValues.workExperience >= 5 &&
        userValues.numberProj >= 5 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Digital Delivery Lead (Tier 2)
            Accreditation
          </div>
        )
      case userValues.education == 'No Relevant Qualifications' &&
        userValues.workExperience >= 6 &&
        userValues.numberProj >= 5 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Digital Delivery Lead (Tier 2)
            Accreditation
          </div>
        )
      case userValues.education == 'Degree' &&
        userValues.workExperience >= 2 &&
        userValues.numberProj >= 3 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Digital Delivery Specialist (Tier 3)
            Accreditation
          </div>
        )
      case userValues.education == 'Diploma/Nitec' &&
        userValues.workExperience >= 3 &&
        userValues.numberProj >= 3 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Digital Delivery Specialist (Tier 3)
            Accreditation
          </div>
        )
      case userValues.education == 'No Relevant Qualifications' &&
        userValues.workExperience >= 4 &&
        userValues.numberProj >= 3 &&
        userValues.yearsLocal >= 2:
        return (
          <div>
            You should apply for the Digital Delivery Specialist (Tier 3)
            Accreditation
          </div>
        )
      default:
        return <div>You do not qualify for DDM accreditation.</div>
    }
  }

  return (
    <>
      <FormHead>
        <h2>Assessment Checklist</h2>
        <Card variant='paper' sx={{ minWidth: ['100%', '30vw', '30vw'] }}>
          <Label color='#537BE5'>
            {!userValues.isSubmit ? 'Details' : 'Result'}
          </Label>

          {!userValues.isSubmit ? (
            <Form onSubmit={event => handleSubmit(event)}>
              <Label>Education</Label>
              <Select
                className='select'
                name='education'
                onChange={event => handleInputChange(event)}
              >
                {optionsA
                  ? optionsA.map(option => <option>{option}</option>)
                  : null}
              </Select>

              <Label>
                {userValues.workExperience} Years of Work Experience
              </Label>
              <Slider
                defaultValue={0}
                className='select'
                name='workExperience'
                onChange={event => handleInputChange(event)}
                max={20}
                color='#537BE5'
              />

              <Label>
                {userValues.yearsLocal} Years of Work Experience in Singapore
              </Label>
              <Slider
                defaultValue={0}
                className='select'
                name='yearsLocal'
                onChange={event => handleInputChange(event)}
                max={20}
                color='#537BE5'
              />

              <Label>
                Worked on {userValues.numberProj} BIM / IDD projects
              </Label>
              <Slider
                defaultValue={0}
                className='select'
                name='numberProj'
                onChange={event => handleInputChange(event)}
                max={20}
                color='#537BE5'
              />
              <Button type='submit' sx={{ margin: '1rem 0' }}>
                Get Result
              </Button>
            </Form>
          ) : (
            renderResult()
          )}
        </Card>
      </FormHead>
    </>
  )
}

export default Asseessment

const Form = styled.form`
  margin: 0.5rem;
  font-size: 1rem;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .select {
    margin-bottom: 1rem;
  }
`

const FormHead = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 4em;
  h2 {
    text-align: center;
  }
`
