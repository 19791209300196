import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { Auth, Storage } from 'aws-amplify'
import styled from 'styled-components'
import { FormInterface } from '../../interfaces/Form'
import { Form } from '../formstyles'
import { Box, Input, Button } from 'theme-ui'

const FileDetails = ({
  step,
  setStep,
  inputType,
  helpText,
  link,
  submissionTier
}: FormInterface) => {
  // TIER-1 APPLICANTS NEED TO SUBMIT A DIFFERENT SET OF DOCUMENTS
  let instructions = helpText
  if (submissionTier === 'tier-1' && step === 5) {
    instructions =
      'Presentation Documents on Organisation Digitalisation Roadmap for evaluation'
  }

  const [files, setFiles] = useState<File[]>([])
  const fileInputType = inputType
  const createdAt = new Date().toISOString().split('T')[0]

  const handleFileChange = ({
    currentTarget: { files }
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
      setFiles(existing => [...existing, ...files])
    }
    console.log('handlefilechangetriggered')
  }

  const renderFilesNames = () => {
    return files.map(file => <Box variant='forms.row'>{file.name}</Box>)
  }

  async function handleBack(event) {
    event.preventDefault()

    if (step === 0) {
      navigate('/')
      return
    }

    setStep(step - 1)
  }

  async function handleContinue(event) {
    event.preventDefault()

    let newArr = files

    if (newArr.length === 0) {
      alert('Some files appear to be missing from your submission')
      return
    }

    for (let i = 0; i < newArr.length; i++) {
      handleUpload(newArr[i])
    }

    setStep(step + 1)
    event.target.reset()
  }

  async function handleUpload(file) {
    let user = await Auth.currentAuthenticatedUser()
    console.log(user)
    let { sub, name, email } = user.attributes
    let newFileName =
      `${email}/` +
      `${name}/ +` +
      `${createdAt}/` +
      `${submissionTier}/` +
      '_' +
      fileInputType +
      '_' +
      file.name

    try {
      await Storage.put(newFileName, file, {
        acl: 'bucket-owner-full-control',
        metadata: { key: `${fileInputType}` },
        contentType: 'text/plain'
      })
      console.log('file upload success')
      setFiles([])
    } catch (err) {
      console.log('Error uploading file: ', err)
    }
  }

  return (
    <Form onSubmit={handleContinue}>
      <Box variant='forms.row'>
        <Box variant='forms.submissions'>
          <p>
            {instructions}
            {` `}
          </p>
          {link ? <Link to={`/${link}`}>here</Link> : null}
          <Input type='file' multiple onChange={handleFileChange} />
          {files ? renderFilesNames() : null}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button variant='primary' type='submit'>
          {' '}
          Continue
        </Button>
        <Button variant='back' onClick={event => handleBack(event)}>
          {' '}
          Back
        </Button>
      </Box>
    </Form>
  )
}

export default FileDetails
