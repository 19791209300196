import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Card, Label, Input, Button, Text } from 'theme-ui'
import { API } from 'aws-amplify'
import { listUsersShort } from '@elegantstack/amplify/graphql/custom-queries'
import { initTiers } from '../../hooks/initTiers'
import { FaCheckCircle } from 'react-icons/fa'
import data from '../../data.json'

const iconStyles = {
  color: 'green',
  marginRight: '0.5em'
}

const VerificationForm = () => {
  const [email, setEmail] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [tiers, setTiers] = useState<string[] | null>([])

  const handleSubmit = event => {
    event.preventDefault()
    getUserTiers()
  }

  const getUserTiers = async () => {
    let user
    try {
      let userData = await API.graphql({
        query: listUsersShort,
        variables: {
          filter: { companyEmail: { eq: email } },
          limit: 10000
        }
      })
      user = userData.data.listUsers.items
    } catch (err) {
      console.log('error fetching user data', err)
    }

    const paymentTier: (String | null)[] = initTiers(user[0])
    if (paymentTier && paymentTier.length > 0) {
      setTiers(paymentTier)
      return
    }
    setTiers(null)
    setStatus(
      `No accreditation data found for ${email}. Please contact us for more details`
    )
  }

  return (
    <>
      <FormHead>
        <h2>Verification</h2>
        <Card
          variant='paper'
          sx={{
            minWidth: ['100%', '30vw', '30vw'],
            maxWidth: ['480px']
          }}
        >
          <Label color='#537BE5'>Details</Label>
          <Text>
            Verify candidate's accreditation status using their associated email
            address.
          </Text>
          <Form onSubmit={event => handleSubmit(event)} style={{ margin: 0 }}>
            <Label>Email</Label>
            <Input
              type='email'
              name='email'
              placeholder='Email Address'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />

            <Button type='submit' variant='primary' sx={{ margin: '1rem 0' }}>
              Verify
            </Button>
            {tiers ? (
              tiers.map(tier => (
                <Box key={tier} mb='2'>
                  <FaCheckCircle style={iconStyles} />
                  {tier}
                </Box>
              ))
            ) : (
              <div>
                No accreditation data found for {email}. If you cannot find the
                information you are looking for, please{' '}
                <a href='mailto:enquiry@buildingsmartsingapore.org'>
                  contact us
                </a>{' '}
                for more details.
              </div>
            )}
          </Form>
        </Card>
      </FormHead>
    </>
  )
}

export default VerificationForm

const Form = styled.form`
  margin: 0.5rem;
  font-size: 1rem;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FormHead = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 4em;
  h2 {
    text-align: center;
  }
`
