import React, { useContext, useEffect, useState } from 'react'
import ProfileForm from './ProfileForm'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Accreditation from '@widgets/Accreditation'
import { Box } from 'theme-ui'
import { initTiers } from '../../hooks/initTiers'

const Profile = ({ user }) => {
  const isMobile = window.innerWidth <= 1040
  const [isComplete, setIsComplete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const confirmedTiers = initTiers(user)

  function checkUserFields() {
    let lengthArr = []
    for (const prop in user) {
      let propLength = user[prop]?.length
      lengthArr.push(propLength)
    }

    if (!lengthArr.includes(0)) {
      setIsComplete(true)
      return
    }
    setIsComplete(false)
  }

  useEffect(() => {
    checkUserFields()
  }, [user, isLoading])

  return (
    <>
      <Seo title='Contact' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Complete your Profile'
            subheader='This information is stored in your account and is used for the assessment of your qualifications and experience towards meeting the accreditation criteria of your desired tier.'
          />

          <ProfileForm
            user={user}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            checkUserFields={checkUserFields}
          />
        </Main>
        <Sidebar>
          <Accreditation isComplete={isComplete} user={user} />
          <Divider />
        </Sidebar>
      </Stack>
    </>
  )
}

export default Profile
