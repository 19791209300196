import React, { useState, useEffect } from 'react'
import {
  Box,
  Label,
  Input,
  Button,
  Divider,
  Heading,
  Select,
  Spinner
} from 'theme-ui'
import { updateUser, createUser } from '@elegantstack/amplify/graphql/mutations'
import Lottie from 'lottie-react'
import lottieCheckmark from 'site/content/assets/lottie-checkmark.json'

import { API, Auth } from 'aws-amplify'
// check form source https://react-hook-form.com/get-started/
import { useForm } from 'react-hook-form'

interface IFormInput {
  firstName: String
  nationality: String
  passStatus: String
  workExperience: Number
  personalPhone: String
  personalEmail: String
  company: String
  companyEmail: String
  supervisorName: String
  supervisorEmail: String
  supervisorPhone: String
  nric: String
  currentDesignation: String
  numberBim: Number
  supervisorDesignation: String
}

const ProfileForm = ({ user, isLoading, setIsLoading }) => {
  const { register, handleSubmit, setValue } = useForm<IFormInput>()

  useEffect(() => {
    if (!user) {
      return
    }

    for (const [key, value] of Object.entries(user)) {
      setValue(`${key}`, `${value}`)
    }
  }, [user])

  async function onSubmit(data: IFormInput) {
    setIsLoading(true)

    let cognitoResponse = await Auth.currentAuthenticatedUser()

    if (user === null) {
      try {
        const createResponse = await API.graphql({
          query: createUser,
          variables: {
            input: {
              id: cognitoResponse.attributes.sub,
              firstName: data.firstName,
              nationality: data.nationality,
              passStatus: data.passStatus,
              workExperience: data.workExperience,
              personalPhone: data.personalPhone,
              personalEmail: data.personalEmail,
              company: data.company,
              companyEmail: data.companyEmail,
              supervisorName: data.supervisorName,
              supervisorEmail: data.supervisorEmail,
              supervisorPhone: data.supervisorPhone,
              nric: data.nric,
              currentDesignation: data.currentDesignation,
              numberBim: data.numberBim,
              supervisorDesignation: data.supervisorDesignation
            }
          }
        })
        console.log('user successfully created!', createResponse)
        return
      } catch (error) {
        console.log('error submitting form', error)
      } finally {
        window.location.reload()
      }
    }

    try {
      const updateResponse = await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: `${user.id}`,
            firstName: data.firstName,
            nationality: data.nationality,
            passStatus: data.passStatus,
            workExperience: data.workExperience,
            personalPhone: data.personalPhone,
            personalEmail: data.personalEmail,
            company: data.company,
            companyEmail: data.companyEmail,
            supervisorName: data.supervisorName,
            supervisorEmail: data.supervisorEmail,
            supervisorPhone: data.supervisorPhone,
            nric: data.nric,
            currentDesignation: data.currentDesignation,
            numberBim: data.numberBim,
            supervisorDesignation: data.supervisorDesignation
          }
        }
      })
      console.log('user successfully updated!', updateResponse)
    } catch (error) {
      console.log('error submitting form', error)
    }
    setTimeout(() => setIsLoading(false), 2000)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading>Personal Particulars</Heading>
      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Name</Label>
          <Input name='firstName' ref={register} />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>Nationality</Label>
          <Input name='nationality' ref={register} />
        </Box>
      </Box>

      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Personal phone</Label>
          <Input name='personalPhone' ref={register} />
        </Box>
        {/* <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>Personal Email</Label>
          <Input name='personalEmail' ref={register} />
        </Box> */}
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>NRIC</Label>
          <Input name='nric' ref={register} />
        </Box>
      </Box>

      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Pass Status</Label>
          <Select name='passStatus' ref={register}>
            <option defaultValue='Singaporean'>Local</option>
            <option value='WP'>Work Permit</option>
            <option value='SP'>S Pass</option>
            <option value='LTVP'>LTVP</option>
            <option value='DP'>Dependency Pass</option>
          </Select>
        </Box>
      </Box>

      <Divider />

      <Heading>Work Experience</Heading>

      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Company Name</Label>
          <Input name='company' ref={register} />
        </Box>
        {/* <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>Company Email</Label>
          <Input name='companyEmail' ref={register} />
        </Box> */}
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>
            Total Work Experience (Years)
          </Label>
          <Input name='workExperience' type='number' ref={register} />
        </Box>
      </Box>

      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Current Designation</Label>
          <Input name='currentDesignation' ref={register} />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Number of BIM/IDD Projects</Label>
          <Input name='numberBim' type='number' ref={register} />
        </Box>
      </Box>

      <Divider />

      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Supervisor Name</Label>
          <Input name='supervisorName' ref={register} />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Supervisor Phone</Label>
          <Input name='supervisorPhone' ref={register} />
        </Box>
      </Box>

      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>Supervisor Email</Label>
          <Input name='supervisorEmail' type='email' ref={register} />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Supervisor Designation</Label>
          <Input name='supervisorDesignation' ref={register} />
        </Box>
      </Box>

      <Box
        variant='forms.submit'
        style={{
          justifyContent: 'center',
          marginTop: '2em'
        }}
      >
        <Button
          type='submit'
          style={{
            alignContent: 'top',
            justifySelf: 'right',
            padding: '0 10px 0 10px'
          }}
        >
          {isLoading ? (
            <Spinner size='24' margin='15px 0.4em 15px 0' />
          ) : (
            <Lottie
              animationData={lottieCheckmark}
              loop={false}
              style={{
                maxWidth: '35px',
                marginTop: '10px'
              }}
            />
          )}
          Update Personal Info
        </Button>
      </Box>
    </form>
  )
}

export default ProfileForm
