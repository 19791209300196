import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { Box, Card, Button, Heading, Badge, Text } from 'theme-ui'
import Section from '@components/Section'
import { initTiers } from '../../hooks/initTiers'
import { loadStripe } from '@stripe/stripe-js'
import { FaCheckCircle } from 'react-icons/fa'

const iconStyles = {
  color: 'green',
  marginRight: '0.5em'
}

const Accreditation = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tiers, setTiers] = useState([])
  const paymentStatus = user?.paymentStatus
  const confirmedTiers = initTiers(user)

  return (
    <>
      <Section id='apply'>
        <Card variant='paper'>
          <Heading>Digital Delivery Management Accreditation</Heading>
          <Text>Your current accreditation: </Text>
          {confirmedTiers
            ? confirmedTiers.map(tier => (
                <Box direction='row' mb='2'>
                  <FaCheckCircle style={iconStyles} />
                  {tier}
                </Box>
              ))
            : null}
          <Button
            sx={{ width: 100 + '%', mb: '1rem' }}
            onClick={() => navigate('/app/register')}
            aria-label='Submit Application'
          >
            Start Application
          </Button>
        </Card>
      </Section>
    </>
  )
}

export default Accreditation
