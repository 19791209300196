import React, { useState, useEffect, createContext } from 'react'
import { Router, useLocation } from '@reach/router'
import { Stack, Main } from '@layout'
import { Amplify, Auth, API, Logger, Hub } from 'aws-amplify'
import { Alert, Close } from 'theme-ui'
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyRequireNewPassword,
  AmplifyForgotPassword
} from '@aws-amplify/ui-react'
import awsmobile from '@elegantstack/src/aws-exports'
import { getUser } from '@elegantstack/amplify/graphql/queries'
import Payment from '@widgets/Payment'
import Register from '@widgets/Register'
import Profile from '@widgets/Profile'
import Forum from '@widgets/Forum'
import Assessment from '@widgets/Assessment'
import VerificationForm from '@widgets/Verification/VerificationForm'
import FooterApp from '../../../../flow-ui/layout/src/Footer/FooterApp'
import { Flex } from 'theme-ui'

Amplify.configure(awsmobile)

export const UserContext = createContext(null)

const App = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [message, setMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const logger = new Logger('Logger', 'INFO')
  const listener = data => {
    switch (data.payload.event) {
      case 'signIn':
        logger.info('user signed in')
        getUserData()
        break
      case 'signUp':
        logger.info('user signed up')
        break
      case 'signOut':
        logger.info('user signed out')
        break
      case 'signIn_failure':
        logger.info('user sign in failed')
        break
      case 'configured':
        logger.info('the Auth module is configured')
        break
      default:
        logger.error('Something went wrong, look at data object', data)
    }
  }

  async function getUserData() {
    let user = await Auth.currentAuthenticatedUser()
    try {
      const userData = await API.graphql({
        query: getUser,
        variables: {
          id: `${user.attributes.sub}`
        }
      })
      setUser(userData.data.getUser)
      setLoading(false)
    } catch (error) {
      console.log('error getting user data', error)
    }
  }

  function checkUrl() {
    if (window.location.href.indexOf('checkout=success') > -1) {
      triggerMessage('Payment received')
      return
    }
  }

  async function triggerMessage(msg) {
    setMessage(msg)
    setIsOpen(true)
  }

  useEffect(() => {
    getUserData()
  }, [loading])

  useEffect(() => {
    checkUrl()
  }, [])

  Hub.listen('auth', listener)

  return (
    <UserContext.Provider value={user}>
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot='sign-up'
          usernameAlias='email'
          headerText='Register Today'
          formFields={[
            {
              type: 'name',
              label: 'First Name',
              required: true,
              placeholder: 'Enter your first name'
            },
            { type: 'email' },
            { type: 'password' },
            {
              type: 'phone_number',
              dialCode: '+65',
              placeholder: '98989898'
            }
          ]}
        />
        <AmplifySignIn
          slot='sign-in'
          headerText='Login to your account'
          formFields={[{ type: 'email' }, { type: 'password' }]}
        />
        <AmplifyForgotPassword
          slot='forgot-password'
          formFields={[{ type: 'email', label: 'Your Email' }]}
        />
        <Flex variant='layout.layout'>
          <Stack>
            <Main>
              {isOpen ? (
                <Alert variant='primary' mt={2}>
                  {message}. Please wait while we process your application.
                  <Close onClick={() => setIsOpen(false)} ml='auto' mr={-2} />
                </Alert>
              ) : null}
              <Router basepath='/app'>
                <Register path='/register' user={user} />
                <Payment path='/payment' user={user} />
                <Profile
                  path='/profile'
                  user={user}
                  triggerMessage={triggerMessage}
                />
                <Forum path='/forum' />
                <Assessment path='/assessment' />
                <VerificationForm path='/verify' />
              </Router>
            </Main>
          </Stack>
          <FooterApp />
        </Flex>
      </AmplifyAuthenticator>
    </UserContext.Provider>
  )
}

export default App
