import React from 'react'
import { Box, Button, Select } from 'theme-ui'
import data from '../../data.json'
import { FormInterface } from '../../interfaces/Form'
import { Form } from '../formstyles'
import { navigate } from 'gatsby'

const SelectAccreditation = ({
  step,
  setStep,
  inputType,
  helpText,
  link,
  setSubmissionTier
}: FormInterface) => {
  const renderFilesNames = () => {
    return files.map(file => <Box variant='forms.row'>{file.name}</Box>)
  }

  async function handleBack(event) {
    event.preventDefault()

    if (step === 0) {
      navigate('/')
      return
    }

    setStep(step - 1)
  }

  async function handleContinue(event) {
    event.preventDefault()

    setStep(step + 1)
    event.target.reset()
  }

  return (
    <Form onSubmit={handleContinue}>
      <Box variant='forms.row'>
        <Box variant='forms.submissions'>
          <text>
            {helpText}
            {` `}
          </text>
          <Select
            name='accreditation'
            onChange={event => setSubmissionTier(event.target.value)}
            required
          >
            <option value=''></option>
            {data
              ? data.map(option => {
                  return <option value={option.name}>{option.label}</option>
                })
              : null}
          </Select>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button variant='primary' type='submit'>
          {' '}
          Continue
        </Button>
        <Button variant='back' onClick={event => handleBack(event)}>
          {' '}
          Back
        </Button>
      </Box>
    </Form>
  )
}

export default SelectAccreditation
