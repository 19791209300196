export const listUsersShort = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        payment_1
        payment_2
        payment_3
        payment_4
        createdAt
      }
      nextToken
    }
  }
`
