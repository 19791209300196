/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      nationality
      passStatus
      workExperience
      personalPhone
      personalEmail
      company
      companyEmail
      supervisorName
      supervisorEmail
      supervisorPhone
      approvalStatus
      certificationNumber
      certificationType
      certificationDate
      updatedAt
      nric
      currentDesignation
      numberBim
      supervisorDesignation
      paymentStatus
      payment_1
      payment_2
      payment_3
      payment_4
      createdAt
      __typename
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      nationality
      passStatus
      workExperience
      personalPhone
      personalEmail
      company
      companyEmail
      supervisorName
      supervisorEmail
      supervisorPhone
      approvalStatus
      certificationNumber
      certificationType
      certificationDate
      updatedAt
      nric
      currentDesignation
      numberBim
      supervisorDesignation
      paymentStatus
      payment_1
      payment_2
      payment_3
      payment_4
      createdAt
      __typename
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      nationality
      passStatus
      workExperience
      personalPhone
      personalEmail
      company
      companyEmail
      supervisorName
      supervisorEmail
      supervisorPhone
      approvalStatus
      certificationNumber
      certificationType
      certificationDate
      updatedAt
      nric
      currentDesignation
      numberBim
      supervisorDesignation
      paymentStatus
      payment_1
      payment_2
      payment_3
      payment_4
      createdAt
      __typename
    }
  }
`
